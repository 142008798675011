import { VueMaskDirective } from "v-mask";
import Vue from 'vue';

import baseApi from "@/helpers/baseApi";
import { translatePlugin } from "@/plugins/i18n.js";

import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import * as Sentry from "@sentry/vue";

import store from './store';

import '@/styles/tailwind.css';
import "@egjs/vue-flicking/dist/flicking.css";
import "@egjs/flicking-plugins/dist/pagination.css";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    Vue,
    dsn: "https://bb38f6170c8045b35544e0875d3fd872@sentry-sh.erg.kz/7",
    integrations: [
      Sentry.browserTracingIntegration(),
    ],
    tracesSampleRate: 1.0,
  });
}

Vue.use(translatePlugin);
Vue.directive('mask', VueMaskDirective);
Vue.config.productionTip = false;
window.api = baseApi;

export const app = new Vue({
  store,
  router,
  vuetify,
  render: h => h(App),
}).$mount('#app');
